import React, {useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import Layout from "../../components/layout";
import Masvistos from "../../components/masvistos";
import GoogleAds from "../../components/googleads";
import Markdown from "react-markdown";
import StructuredDataNews from "../../components/structuredDataNews";
import Newsletter from "../../components/newsletter";
import Anteriorsiguientetrucos from "../../components/anteriorsiguientetrucos";
import Gridreellateral from "../../components/reelslateral";

// categoria_noticia: {nombre: {eq: "Trucos de cocina"}}
export const query = graphql`
  query TrucosQuery($slug: String!) {
    strapiNoticiasConsejosTrucos(
      slug: { eq: $slug }
     ) {
      id
      slug
      strapiId
      subtitulo
      subtitulo_home
      published_at (formatString: "YYYY-MM-DD")
      updatedAt (formatString: "YYYY-MM-DD")
      texto_abajo
      texto_arriba
      categoria_noticia {
        nombre
        slug
      }
      titulo
      entradilla
      writer {
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, outputPixelDensities: 1.5)
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      imagenes {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            id
          }
        }
      }
    }
  }
`;

const Detailtrucos = ({ data }) => {
  const noticiapage = data.strapiNoticiasConsejosTrucos;

  if(noticiapage.categoria_noticia.nombre !="Trucos de cocina") {
    if (typeof window !== 'undefined') {
      window.location = '/' + noticiapage.categoria_noticia.slug + '/' + noticiapage.slug;
    }
    return null;
  }

  const seo = {
    metaTitle: noticiapage.titulo,
    metaDescription: noticiapage.seo.metaDescription,
    shareImage: noticiapage.seo.shareImage,
    article: true,
    url: "/trucos-de-cocina/" + noticiapage.slug
  };
  const richsnippetsnews = {
    valido:true,
    titulo: noticiapage.titulo,
    fechapublicado: noticiapage.published_at,
    fechamodificado: noticiapage.updatedAt,
    imagen: noticiapage.seo.shareImage,
    urlnews: "/trucos-de-cocina/" + noticiapage.slug
  }
  const slugnoticias = noticiapage.slug;

  return (
    <Layout seo={seo}>
      <StructuredDataNews richsnippetsnews={richsnippetsnews} />
        <div>
        <div className="relative bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-base max-w-screen-lg mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="col-span-3 ...">
              <a href="https://lacocinasana.com/clubcocinasana/" target="_blank">
                  <img
                       alt="baja de peso sin dietas"
                       src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/nuevoanuncio_3590cef121.jpg"
                       className="w-full mb-4 hidden md:block"
                    />
                    <img
                       alt="baja de peso sin dietas"
                       src="https://recetascocinasana.s3.eu-west-1.amazonaws.com/movil_metodo_cocina_sana_2aea3ccd28.jpg"
                       className="w-full mb-4 block md:hidden"
                    />   
                </a>
              {noticiapage.imagenes.map((imagensingle) => (
              <GatsbyImage key={imagensingle.localFile.childImageSharp.id}
                alt={`${noticiapage.titulo}`}
                image={imagensingle.localFile.childImageSharp.gatsbyImageData}
                className="w-full object-cover aspect-w-6 md:aspect-h-4 aspect-h-5"
              />
              ))}
                 <p className="uppercase leading-5 text-lg text-verde mt-6 text-center font-bold">{noticiapage.subtitulo}</p>
                 <h1 className="mt-2 block text-3xl text-center leading-8 sm:leading-10  poppins w500 text-gray-900 sm:text-s36">{noticiapage.titulo}</h1>
                <p className="circularlight text-lg md:text-s22 text-center md:leading-8 mt-3 underline px-4 ">{noticiapage.entradilla}</p>
                <div className="mt-4 text-s22 leading-9 archebook recetapersonal" dangerouslySetInnerHTML={{__html: noticiapage.texto_arriba}}></div>
                <GoogleAds/>
                <div className="mt-4 text-s22 leading-9 archebook recetapersonal" dangerouslySetInnerHTML={{__html: noticiapage.texto_abajo}}></div>
                <div className="border-b-4 border-amarillolineas mt-4 "></div>
     
                <div className="bg-gray-50 px-4 py-4 md:px-9 md:py-9">
                  
                 
                </div>
                </div>
                <div className="md:pl-6 md:ml-2 md:border-l">
                  <div className="text-marron poppins w500 bg-amarillo text-xl text-center py-3">LO MÁS VISTO</div>
                  <Masvistos/>
                  <div className="text-marron poppins w500 bg-amarillo text-xl text-center py-3 mt-8">VÍDEO RECETAS</div>
                  <Gridreellateral/>
                  <GoogleAds/>
                  <a href="https://lacocinasana.com/clubcocinasana/calculadora-tasa-metabolica-basal/" target="_blank">
                  <img
                      alt="calculadora metabólica basal"
                      src='https://recetascocinasana.s3.eu-west-1.amazonaws.com/calculadora_metabolica_57af3dface.jpg'
                      className="pb-4 pt-4 mx-auto"
                  /> 
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div> 
        <Anteriorsiguientetrucos slugnoticia = {slugnoticias} /> 
        <Newsletter />          
    </Layout>
  );
};

export default Detailtrucos;